
const VALID_LANGUAGES = [ 'en', 'no' ];

export const currentLocationLanguage = (location) => {

    const match = location.pathname.match(/^\/(?<language>[a-z]{2,})\/.*$/);
    if (match && match.groups && match.groups.language) {
        const language = match.groups.language;
        if (isValidLanguage(language)) {
            return language;
        }
    }

    return null;
}

export const isValidLanguage = (language) => VALID_LANGUAGES.includes(language);
