
import * as React from 'react';
import { currentLocationLanguage } from '../utils/languages';
import createPersistedState from 'use-persisted-state';

const reducer = (currentLanguage, newLanguage) => {
    // Never overwrite to null.
    return newLanguage ? newLanguage : currentLanguage;
};

const makeDefaultState = (location) => currentLocationLanguage(location)

export const LanguageContext = React.createContext();

export const LanguageProvider = (props) => {

    // Default stored state - null if landing on /.
    const defaultState = makeDefaultState(props.location);

    // Fetch stored value.
    const useLanguageState = createPersistedState('site-language');
    const [storedLanguage, setStoredLanguage] = useLanguageState(defaultState);

    // Overwrite with current page value.
    const language = reducer(storedLanguage, defaultState);

    // Update in localstorage
    React.useEffect(() => {
        setStoredLanguage(language);
    });

    return (
        <LanguageContext.Provider value={language}>
            {props.children}
        </LanguageContext.Provider>
    );
}
